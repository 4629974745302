export default{
    inject: ['myClass', 'textOpen', 'textClose'],
    data(){
        return {
            isOpen: false,
            thisTextOpen: this.textOpen,
            thisTextClose: this.textClose
        }
    },
    template: `
    <button :value="valueObject" :class="[myClass + '_btn', classObject ]" @click="onclick">
        <span :class="[myClass + '_btn_inner', classObject ]">{{valueObject}}</span>
    </button>
    <transition>
        <div v-show="isOpen" v-bind:class="[myClass + '_target',classObject]">
            <slot></slot>
        </div>
    </transition>
    `,
    computed: {
        classObject() {
            return this.isOpen ? '__isOpened' : '__isClosed'
        },
        valueObject() {
            return this.isOpen ? this.thisTextClose : this.thisTextOpen
        }
    },
    methods: {
        onclick() {
            this.isOpen = !this.isOpen
            const body = document.getElementsByTagName('body')[0]
            if(this.isOpen){
                body.classList.add('__navOpened')
            }else{
                body.classList.remove('__navOpened')
            }
        }
    },
}