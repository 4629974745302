export default{
    props: {
        myClass: {
            type: String,
            default: "v-responsive-nav"
        },
        textOpen: {
            type: String,
            default: "Open the index"
        },
        textClose: {
            type: String,
            default: "Close the index"
        }
    },
    data(){
        return {
            isOpen: false,
        }
    },
    template: `
    <input type="button" :value="valueObject" :class="[myClass + '_btn', classObject ]" @click="onclick">
    <transition>
        <div v-show="isOpen" v-bind:class="[myClass + '_target', classObject]">
            <slot></slot>
        </div>
    </transition>
    `,
    computed: {
        classObject() {
            return this.isOpen ? '__isOpened' : '__isClosed'
        },
        valueObject() {
            return this.isOpen ? this.textClose : this.textOpen
        }
    },
    methods: {
        onclick() {
            this.isOpen = !this.isOpen
        }
    },
}