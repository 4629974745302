require('intersection-observer')

import NcScrollAnim from './components/nc_scroll-anim.js'
import NcHamburger from './components/nc_hamburger.js'
import NcResponsiveNav from './components/nc_responsive_nav.js'
import NcPageTop from './components/nc_page_top.js'
const options = {
    data(){
      return {
          app: {
              width: 0,
              isPC: false
          },
      }
    },
    created() {
        window.addEventListener('resize', this.setWindowWidth, false);
        this.setWindowWidth();
    },
    methods: {
        setWindowWidth() {
            // this.app.width = window.innerWidth;
            // if(this.app.width > 768){
            //     this.app.isPC = true
            // }else{
            //     this.app.isPC = false
            // }
            if(window.innerWidth >= 1024){
                this.app.isPC = true
            }else{
                this.app.isPC = false
            }
        } 
    },
    provide() {
        return {
            app: this.app,
        }
    },
}

Vue.createApp({})
.component('NcScrollAnim', NcScrollAnim)
// .component('NcMap', NcMap)
.mount('#app')

Vue.createApp(options)
.component('NcScrollAnim', NcScrollAnim)
.component('NcHamburger', NcHamburger)
.component('NcResponsiveNav', NcResponsiveNav)
// .component('NcMap', NcMap)
.mount('#header')

Vue.createApp({})
.component('NcPageTop', NcPageTop)
.mount('#footer')

VANTA.NET({
  el: "#bg",
  mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 500.00,
  minWidth: 200.00,
  scale: 1.00,
  scaleMobile: 1.00,
  color: 0x000000,
  backgroundColor: 0xffffff,
  points: 7,
  maxDistance: 20,
  spacing: 20
})

// loading
window.onload = function() {
const spinner = document.getElementById('loading');
spinner.classList.add('__loaded');
}