import NcResponsiveHamburger from './nc_responsive_hamburger.js'
export default{
    inject: ['app'],
    components: {
        'nc-responsive-hamburger' : NcResponsiveHamburger,
    },
    props: {
        myClass: {
            type: String,
            default: "v-responsive-nav"
        },
        textOpen: {
            type: String,
            default: "Open the index"
        },
        textClose: {
            type: String,
            default: "Close the index"
        }
    },
    data(){
        return {
            isPC: true,
        }
    },
    template: `
    <div :class="myClass">
        <slot v-if="app.isPC"></slot>
        <nc-responsive-hamburger v-if="!app.isPC">
            <slot></slot>
        </nc-responsive-hamburger>
    </div>
    `,
    provide() {
        return {
            myClass: this.myClass,
            textOpen: this.textOpen,
            textClose: this.textClose
        }
    },
}