export default{
    props: {
        myClass: {
            type: String,
            default: 'v-page-top'
        },
        myScrollY: {
            type: Number,
            default: 300
        }
    },
    data(){
        return {
            scrolled: false
        }
    },
    template: `
    <button @click="scroll" :class="[myClass, classObject]"><slot></slot></button>
    `,
    computed: {
        classObject() {
            return (
                this.scrolled ? '_isShown' : '__isHidden'
            )
        },
    },
    methods: {
        scroll: function() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
    },
    mounted() {
        let timer = null
        const scrollEvent = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                const scrollY = window.pageYOffset
                if(scrollY > this.myScrollY){
                    this.scrolled = true
                }else{
                    this.scrolled = false
                }
            }, 300)
        }
        window.onscroll = scrollEvent
    },
}